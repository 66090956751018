<div class="py-8">
  <div class="container px-0">
    <div class="container p-4 xl:my-4 my-0 xl:px-2">
      <table class="grid justify-center gap-4">
        <tr>
          <h1 class="lg:mb-5 mb-0 xl:py-2 text-2xl md:text-4xl pb-8 lg:pb-0">Datenrettungsdienst / Datenrettung / Datenretter: 
          </h1>
          <span class="col-span-11 text-base xl:text-lg">
            <p>Professionelle Datenrettung & IT-Forensik zu transparenten und fairen Konditionen.</p><br>
            <p></p>
           
          </span>
        </tr>
        <tr>

          <h3 class="col-span-11 text-lg font-semibold underline py-4 pb-2">
            Für welche Speicher? </h3>
          <span class="col-span-11 text-base xl:text-lg">
              <h2><a href="/datenrettung/arbeitsweise" class=""
              ><strong>Datenrettung</strong></a> für defekte <a href="/datenrettung/hdd" class=""
              ><strong>HDD Festplatte</strong>   </a>, <a href="/datenrettung/ssd" class=""
              ><strong>SSD Speicher</strong></a>, <a href="/datenrettung/usb" class=""
              ><strong>USB Sticks</strong></a>, <a href="/datenrettung/sd" class=""
              ><strong>micro SD- und SD-Karten</strong></a> oder <a href="/datenrettung/raid" class=""
              ><strong>RAID / NAS</strong></a>.</h2>
              <p>Auch für Speicher mit mechanischen Defekt ("klackernde Festplatte"), nicht reagieren oder nicht erkannt werden.</p>



              <div class="flex flex-row justify-around flex-wrap my-12 xl:gap-0 gap-2 text-center" style="margin-left:-25px;">
                <div class="flex flex-col items-center w-24 xl:w-72">
                  <span>
                    wird nicht erkannt?
                  </span>
                  <img class="max-h-20 xl:max-h-24" src="/assets/favicon/sd.png" alt="SD Karte reparieren">
                </div>
                <div class="flex flex-col items-center w-24 xl:w-72">
                  <span>
                    ungewöhnliche Geräusche?
                  </span>
                  <img class="max-h-20 xl:max-h-24" src="/assets/favicon/hdd.png" alt="Datenrettung HDD">
                </div>
                <div class="flex flex-col items-center w-24 xl:w-72">
                  <span>
                    Eigenversuche fehlgeschlagen?
                  </span>
                  <img class="max-h-20 xl:max-h-24" src="/assets/favicon/software.png" alt="Datenrettungssoftware">
                </div>
              </div>
            </span>



          <span class="col-span-11 text-lg font-semibold underline py-4 pb-2">
            Mit welchen Mitteln? </span>
          <span class="col-span-11 text-base xl:text-lg">
              <p>Mit Datenrettungstechnik von ACE Lab, HDD Surgery und Dolphin Data Lab.</p><br>
              <p>ESD geschützt und nach <strong>DIN 14644-1 ISO 5 Reinraumklasse</strong> für HDD-Festplatten.</p>
            </span>
          <span class="col-span-11 text-base xl:text-lg">
              <p>Alle Arbeiten werden unter Laborbedingungen in <a href="/rechtliches/impressum" class="">Ingolstadt</a> vor Ort durchgeführt.</p>
            </span>
          <br>
          <img href="/datenrettung/arbeitsweise" class="max-h-64 " src="/assets/articles/merged.webp"
               alt="datenrettung">
          <br>
          <span class="col-span-11 text-base xl:text-lg">
              <p>Weiteres zu den Methoden der Datenrettung & IT-Forensik finden Sie unter <a
                href="/datenrettung/arbeitsweise" class="underline">Arbeitsweise</a>.</p>
            </span>
          <br>
          <br>
          <h2 class="col-span-11 text-lg font-semibold underline py-4 pb-2">
            Zu welchen Preisen? </h2>
          <span class="col-span-11 text-base xl:text-lg">
                      <p>Festpreise aufgeteilt in "einfache" und "komplexe" Fälle <a
                        href="/datenrettung/einschätzung" target="_blank" class="semibold"><b>ⓘ</b></a>.</p>
          </span>



          <span class="col-span-11 text-base xl:text-lg">
                <p>Bezahlung <strong>nur nach Erfolg</strong>: 95%+ oder nach Ferneinsicht die Bestätigung der Übernahme der Datenrettung.</p>
              </span> <br>

          <span class="col-span-11 text-base xl:text-lg">
                <p><strong>Alle Kosten</strong>, z.B. auch Teilespender oder Leihdatenträger, sind <strong>im Festpreis inklusive</strong>.</p>
                <p>Es handelt sich um Endpreise. D.h. es sind ausdrücklich keine "ab Preise".</p>
              </span><br>
            


              <div class="flex flex-row justify-around flex-wrap xl:gap-0 gap-2 my-12 text-center" style="margin-left:-5px;">
                <div class="flex flex-col text-base xl:text-lg items-center w-24 xl:w-72">
                  <span>
                    transparente Festpreise
                  </span>
                  <img class="max-h-24 xl:max-h-32 py-2 xl:py-4" src="/assets/favicon/festpreis.png"
                  alt="Datenrettung Preise">
                </div>
                <div class="flex flex-col text-base xl:text-lg items-center w-24 xl:w-72">
                  <span>
                    keine "ab X€" Lockangebote
                  </span>
                  <img class="max-h-24 xl:max-h-32 py-2" src="/assets/favicon/pfau.png" alt="Datenrettung Lockangebot">
                </div>
                <div class="flex flex-col text-base xl:text-lg items-center w-32 xl:w-72">
                  <span>100% kostenfrei bei Misserfolg
                  </span>
                  <img class="max-h-24 xl:max-h-32" class="xl:mt-4 xl:py-3 py-4" src="/assets/favicon/frei.png" alt="Datenrettung kostenlos">
                </div>
              </div>
            <br>

          <span class="col-span-11 text-base xl:text-lg">
                <p>Alle Festpreise finden Sie offen & transparent im Bereich <a href="/preise" class="underline">Preise</a>.</p>
              </span><br><br><br>
          <div class="col-span-11 text-center text-xl xl:text-2xl">
            <button class="px-4 py-4 text-black bg-white rounded shadow">
              Tel.: <a
              href="tel:0841 12840705" class=""
            >0841 12840705</a>
            </button>
          </div>
      </table>
    </div>
  </div>
</div>
<app-slide-show></app-slide-show>
<app-review></app-review>
<app-price-list></app-price-list>
<div class="py-10 bg-white">
  <div class="container flex flex-col justify-center gap-8 lg:flex-row">
    <div class="inline-block">
      <h2 class="mb-8 text-2xl xl:text-3xl text-center text-gray-900">
        <a href="/datenrettung/ablauf-der-datenrettung-anhand-eines-beispiels" class="underline">
          Schritte zur Datenrettung: </a>
      </h2>
      <div class="grid justify-center py-8">
        <div class="relative flex pb-8">
          <div
            class="absolute inset-0 flex items-center justify-center w-12 h-full"
          >
            <div class="w-1 h-full bg-gray-300 pointer-events-none"></div>
          </div>
          <div
            class="relative z-10 inline-flex items-center justify-center flex-shrink-0 w-12 h-12 text-white rounded-full bg-silver"
          >
            <svg
              class="feather feather-check-square"
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#000"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <polyline points="9 11 12 14 22 4"></polyline>
              <path
                d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"
              ></path>
            </svg>
          </div>
          <div class="flex-grow pl-4">
            <span class="mb-1 text-lg xl:text-xl font-bold text-black">
              Passendes Angebot zur Datenrettung auswählen:
            </span>

            <p class="text-base xl:text-lg">Hier ist eine <a href="/datenrettung/einschätzung"
                                                                   class="underline">Unterteilung in "einfache" und
              "komplexe" Fälle</a>. </p>
            <p class="text-base xl:text-lg">Kontaktieren Sie mich zur Abklärung des Schadens gerne per Telefon: <a
              href="tel:0841 12840705" class="">0841 12840705</a>.</p>

          </div>
        </div>
        <div class="relative flex pb-8">
          <div
            class="absolute inset-0 flex items-center justify-center w-12 h-full"
          >
            <div class="w-1 h-full bg-gray-300 pointer-events-none"></div>
          </div>
          <div
            class="relative z-10 inline-flex items-center justify-center flex-shrink-0 w-12 h-12 text-white rounded-full bg-silver"
          >
            <svg
              class="feather feather-edit"
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#000"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path
                d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
              ></path>
              <path
                d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
              ></path>
            </svg>
          </div>
          <div class="flex-grow pl-4">
            <h3 class="mb-1 text-lg xl:text-xl font-bold text-black">
              Auftragsformular:
            </h3>
            <p class="text-base xl:text-lg">
              Möglichst viele Details helfen für eine erfolgreiche & effiziente Datenrettung.

            </p>

            <p class="text-base xl:text-lg">
              Bei Misserfolg würden inkl. Rückversand des Originalspeichers
              keine Kosten entstehen.
            </p>
          </div>
        </div>
        <div class="relative flex pb-8">
          <div
            class="absolute inset-0 flex items-center justify-center w-12 h-full"
          >
            <div class="w-1 h-full bg-gray-300 pointer-events-none"></div>
          </div>
          <div
            class="relative z-10 inline-flex items-center justify-center flex-shrink-0 w-12 h-12 text-white rounded-full bg-silver"
          >
            <svg
              class="feather feather-package"
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#000"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line>
              <path
                d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"
              ></path>
              <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
              <line x1="12" y1="22.08" x2="12" y2="12"></line>
            </svg>
          </div>
          <div class="flex-grow pl-4">
            <span class="mb-1 text-lg xl:text-xl font-bold text-black">
              Versand:
            </span>
            <p class="text-base xl:text-lg">
              Bitte versenden Sie den Speicher verfolgbar z.B. als DHL Paket.
            </p>
            <p class="text-base xl:text-lg">
              Abgaben vor Ort mit gemeinsamer Analyse sind nach Terminabsprache möglich.
            </p>
          </div>
        </div>
        <div class="relative flex pb-8">
          <div
            class="absolute inset-0 flex items-center justify-center w-12 h-full"
          >
            <div class="w-1 h-full bg-gray-300 pointer-events-none"></div>
          </div>
          <div
            class="relative z-10 inline-flex items-center justify-center flex-shrink-0 w-12 h-12 text-white rounded-full bg-silver"
          >
            <svg
              viewBox="0 0 24 24"
              width="24"
              height="24"
              stroke="#000"
              stroke-width="2"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="css-i6dzq1"
            >
              <path
                d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"
              ></path>
              <line x1="12" y1="11" x2="12" y2="17"></line>
              <line x1="9" y1="14" x2="15" y2="14"></line>
            </svg>
          </div>
          <div class="flex-grow pl-4">
            <span class="mb-1 text-lg xl:text-xl font-bold text-black">
              Datenrettung:
            </span>
            <p class="text-base xl:text-lg">
              Sie erhalten ein Trackinglink womit sich der Status überprüfen lässt. Bei Fragen sehr gerne anrufen.
            </p>
            <p class="text-base xl:text-lg">
              Die geretteten Dateien werden auf einem weiteren Datenträger
              abgespeichert.
            </p>
          </div>
        </div>
        <div class="relative flex pb-8">
          <div
            class="relative z-10 inline-flex items-center justify-center flex-shrink-0 w-12 h-12 text-white rounded-full bg-silver"
          >
            <svg
              class="feather feather-truck"
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#000"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <rect x="1" y="3" width="15" height="13"></rect>
              <polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon>
              <circle cx="5.5" cy="18.5" r="2.5"></circle>
              <circle cx="18.5" cy="18.5" r="2.5"></circle>
            </svg>
          </div>
          <div class="flex-grow pl-4">
            <span class="mb-1 text-lg xl:text-xl font-bold text-black">
              Rückversand:
            </span>
            <p class="text-base xl:text-lg">
              Die gesicherten Daten werden zusammen mit der Rechnung per DHL Paket (versichert) zugesandt.
            </p>
            <p class="text-lg xl:text-xl"></p>
          </div>
        </div>
      </div>
      <div class="flex justify-end pt-4">
        <button
          class="px-4 py-2 text-lg xl:text-xl text-white rounded shadow bg-gray-main">
          <a href="/datenrettung/ablauf-der-datenrettung-anhand-eines-beispiels">
            Beispiel </a>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="bg-white">
  <div class="container py-10 bg-white px-3 ">
    <div
      class="text-white bg-gray-main rounded-2xl mb-[15rem]"
      style="box-shadow: 0 2px 25px 0 black"
      id="order_form"
    >
      <app-order-form
        *ngIf="products && categories && orderSubmitted === null"
        [products]="products"
        [categories]="categories"
        [replacementProducts]="replacementProducts"
        (orderOutput)="submitOrder($event)"
      ></app-order-form>

      <div *ngIf="orderSubmitted === false">
        <div class="h-6 bg-silver rounded-t-2xl"></div>
        <div class="grid grid-cols-2">
          <div
            class="bg-center bg-cover"
            style="
              background-image: url('/assets/index/order_confirmation.png');
            "
          ></div>
          <div class="p-8 text-center">
            <p class="py-4 text-lg text-lg text-silver">
              Ihre Anfrage wurde nicht übermittelt<br/>Bitte kontaktieren sie
              Tobias Jungbauer über eine Email an:
              <span class="font-bold">info@jungbauerdatenrettung.de</span>
            </p>
          </div>
        </div>
        <div class="h-6 bg-silver rounded-b-2xl"></div>
      </div>

      <div *ngIf="orderSubmitted === true || !products" id="order_submit">
        <div class="h-6 bg-silver rounded-t-2xl"></div>
        <div class="grid grid-cols-2" *ngIf="orderSubmitted === true">
          <div
            class="bg-center bg-cover"
            style="
              background-image: url('/assets/index/order_confirmation.png');
            "
          ></div>
          <div class="py-8 text-center">
            <p class="py-4 text-lg text-silver">
              Ihre Anfrage wurde erfolgreich übermittelt. <br/>
              Sie erhalten eine Bestätigung per E-Mail.
            </p>
          </div>
        </div>

        <div *ngIf="!products" class="py-8 text-center">
          <p class="pb-8">
            Bitte kontaktieren sie Tobias Jungbauer über eine Email an:
            <span class="font-bold">info@jungbauerdatenrettung.de</span>
          </p>
          <button class="px-4 py-2 text-black bg-white rounded shadow">
            <a
              href="mailto:info@jungbauerdatenrettung.de?subject=Anfrage%20wegen%20einer%20Datenrettung&amp;"
            >Hier klicken um eine Email zu senden</a
            >
          </button>
        </div>
        <div class="h-6 bg-silver rounded-b-2xl"></div>
      </div>
    </div>
    <app-about-me></app-about-me>
    <div class="fixed left-4 bottom-4 z-50 flex flex-row hidden 2xl:block w-96">
      <a href="https://de.trustpilot.com/review/jungbauerdatenrettung.de" target="_blank" rel="noopener">
        <img class="rounded-xl shadow-xl" 
        src="/assets/trustpilot.png"
        alt="Trustpilot Tobias Jungbauer Datenrettung">
      </a>
    
      
      </div>
  </div>
</div>
